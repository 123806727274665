.dashboard_announcements_box .announcement_img_show img {
  width: 100%;
  /* max-width: 550px; */
  height: 260px !important;
  object-fit: contain;
  display: flex;
  margin: 0 auto;
  border-radius: 20px !important;
}
.Toastify__toast-container {
  z-index: 999999 !important;
}
