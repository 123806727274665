.file_div {
  margin-top: 25px;
  display: flex;
}

.file_div label {
  padding: 10px 10px;
  color: #00abe0;
  background-color: #f3f8fe;
  cursor: pointer;
  border-radius: 15px;
  font-size: 16px;
  margin-right: 10px;
}

.file_div label svg {
  margin-right: 5px;
  font-size: 16px;
}

.file_div input[type="file"] {
  display: none;
}

.announcement_create_btn {
  padding: 10px 40px !important;
  background: linear-gradient(to bottom, #0097d9, #00b1ff) !important;
  box-shadow: 0 15px 20px 0 rgb(13 110 253 / 30%) !important;
  border: none !important;
  font-size: 16px !important;
  font-weight: 500 !important;
  border-radius: 10px !important;
  color: white !important;
}

.create_announcement {
  box-shadow: 0px 9px 15px rgb(0 174 250 / 10%) !important;
  padding: 10px;
  margin: 0 auto;
  background-color: #fff;
  border-radius: 10px;
  margin-top: 10px !important;
  margin-bottom: 40px;
  border-top-left-radius: 17px;
  border-top-right-radius: 17px;
}

.header_line {
  height: 17px;
  background-color: #0096d8;
  margin: -13px;
  margin-bottom: 10px;
  border-top-left-radius: 20px;
  border-top-right-radius: 20px;
}

.form_div {
  display: flex;
  align-items: center;
  width: 100%;
}

.form_div h6 {
  margin-bottom: 0;
}

.form_div h5 {
  margin-top: 5px;
  font-size: 14px;
  font-weight: 600;
}

.form_div img {
  width: 50px;
  height: 50px;
  border-radius: 100px;
  margin-right: 15px;
  object-fit: cover;
}

#scrollableDiv {
  margin-top: 50px;
}

.infinite-scroll-component::-webkit-scrollbar {
  width: 5px;
  cursor: pointer;
}

.infinite-scroll-component::-webkit-scrollbar-track {
  background: #e4e4e5;
}

.infinite-scroll-component::-webkit-scrollbar-thumb {
  background: #14d4e2;
  border-radius: 10px;
  outline: 1px solid #fff;
  cursor: pointer;
}

/*  */

.announcement_img_show img {
  width: 100%;
  /* max-width: 550px; */
  height: 400px;
  object-fit: contain;
  display: flex;
  margin: 0 auto;
  border-radius: 20px !important;
}

.announcement_img_show p {
  padding-left: 15px;
}

.user_img {
  width: 45px;
  height: 45px;
  border-radius: 100px;
  margin-right: 10px;
  object-fit: contain;
}

.user_list {
  list-style: none;
  overflow-y: scroll;
  height: 350px;
}

.user_list::-webkit-scrollbar {
  width: 5px;
}

.user_list::-webkit-scrollbar-thumb {
  background: #00abe0;
  border-radius: 10px;
  outline: 1px solid #00abe0;
}

.user_list_item {
  margin-right: 2em;
  list-style: none;
  border-bottom: 1px solid lightgrey;
}

.list_name {
  align-items: center;
}

.list_name > input[type="checkbox"] {
  width: 20px !important;
  height: 20px !important;
  background-color: #00abe0 !important;
  color: #00abe0 !important;
  accent-color: #00abe0 !important;
  cursor: pointer;
}

.form_div .form_group {
  width: 100%;
}

.file_div label {
  padding: 7px 10px;
  color: #00abe0;
  background-color: whitesmoke;
  cursor: pointer;
  border-radius: 15px;
  font-size: 14px;
  margin-right: 10px;
}

.file_div label svg {
  margin-right: 5px;
  font-size: 15px;
}

.announcement_modal .Loader,
#scrollableDiv .Loader {
  position: unset !important;
}

.share_with_btn_container {
  text-align: end !important;
  padding: 0px 30px;
}

.share_with_btn_container button {
  padding: 6px 26px !important;
  background: linear-gradient(to bottom, #0097d9, #00b1ff) !important ;
  box-shadow: 0 15px 20px 0 rgb(13 110 253 / 30%) !important;
  border: none !important;
  font-size: 16px !important;
  font-weight: 500 !important;
  border-radius: 10px !important;
  color: white !important;
}
