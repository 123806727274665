.rbc-today {
  background-color: #0096d8 !important;
}

.rbc-current button {
  color: #fff !important;
}

.card .card-header {
  border-bottom: 2px solid #f1f1f1;
  background-color: #0096d8;
  color: #fff;
  margin-bottom: 15px;
  display: flex;
  justify-content: space-between;
}

.card-header h5 {
  margin-bottom: 5px;
}

.rbc-toolbar-label {
  color: #253766 !important;
  font-size: 14px !important;
  font-weight: 700;
  padding: 0% !important;
}

.rbc-active {
  background-color: #0096d8 !important;
  color: #fff !important;
}
.rbc-toolbar > .rbc-btn-group:last-child > button:nth-child(1) {
  background: linear-gradient(to bottom, #0097d9, #00b1ff) !important;
  color: white;
  border: none !important;
  margin-right: 2px !important;
  border-top-left-radius: 10px !important;
  border-bottom-left-radius: 10px !important;
  font-size: 12px !important;
}
.rbc-toolbar > .rbc-btn-group:last-child > button:nth-child(1):hover {
  box-shadow: 0px 8px 20px rgba(8, 100, 141, 45%) !important;
}
.rbc-toolbar > .rbc-btn-group:last-child > button:nth-child(2):hover {
  box-shadow: 0px 8px 20px rgba(8, 100, 141, 45%) !important;
}
.rbc-toolbar > .rbc-btn-group:last-child > button:nth-child(3):hover {
  box-shadow: 0px 8px 20px rgba(8, 100, 141, 45%) !important;
}
.rbc-toolbar > .rbc-btn-group:last-child > button:nth-child(2) {
  background: linear-gradient(to bottom, #0097d9, #00b1ff) !important;
  color: white;
  border: none !important;
  margin-right: 2px !important;
  font-size: 12px !important;
}
.rbc-toolbar > .rbc-btn-group:last-child > button:nth-child(3) {
  background: linear-gradient(to bottom, #0097d9, #00b1ff) !important;
  color: white;
  border: none !important;
  border-top-right-radius: 10px !important;
  border-bottom-right-radius: 10px !important;
  font-size: 12px !important;
}
.rbc-toolbar > .rbc-btn-group:first-child > button:first-child {
  background-color: #59c680 !important;
  color: white;
  margin-right: 10px;
  border: none !important;
  border-radius: 10px !important;
  font-size: 12px !important;
}
.rbc-toolbar > .rbc-btn-group:first-child > button:nth-child(2) {
  background: linear-gradient(to bottom, #0097d9, #00b1ff) !important;
  color: white;
  border: none !important;
  border-top-left-radius: 10px !important;
  border-bottom-left-radius: 10px !important;
  margin-right: 2px;
  font-size: 12px !important;
}
.rbc-toolbar > .rbc-btn-group:first-child > button:nth-child(2):hover {
  box-shadow: 0px 8px 20px rgba(8, 100, 141, 45%) !important;
}
.rbc-toolbar > .rbc-btn-group:first-child > button:nth-child(3) {
  background: linear-gradient(to bottom, #0097d9, #00b1ff) !important;
  color: white;
  border: none !important;
  border-top-right-radius: 10px !important;
  border-bottom-right-radius: 10px !important;
  font-size: 12px !important;
}
.rbc-toolbar > .rbc-btn-group:first-child > button:nth-child(3):hover {
  box-shadow: 0px 8px 20px rgba(8, 100, 141, 45%) !important;
}
/* .rbc-event .rbc-selected {
  background-color: #8c63ee !important;
}
.rbc-event {
  background-color: #8c63ee !important;
} */
.rbc-today {
  background-color: #59c680 !important;
}
