.notification_icon_container {
  margin-right: 30px;
}

.notification_icon_container svg {
  font-size: 25px;
  cursor: pointer;
}

.notification_icon_container .MuiBadge-badge {
  background-color: red;
  color: white;
}

.bell-icon {
  color: #3077f2;
}
