.employees_main_selection {
  padding: 20px;
  background-color: white;
  margin-top: 15px;
  border-bottom-left-radius: 15px;
  border-bottom-right-radius: 15px;
}

.employee_id_main_col {
  margin-left: 20px;
}

.search_btn_container {
  display: flex;
  justify-content: flex-end;
  align-items: flex-end;
  width: 100%;
}

.search_btn_container button {
  padding: 10px 20px;
  background: linear-gradient(to bottom, #0097d9, #00b1ff);
  box-shadow: 0 15px 20px 0 rgb(13 110 253 / 30%);
  border: none;
  width: 100%;
  max-width: 170px;
  font-size: 12px;
  font-weight: 500;
  border-radius: 10px;
  color: white;
}

.search_btn_container button svg {
  margin-right: 5px;
  font-size: 17px;
}

.add_employees_btn_container {
  display: flex;
  justify-content: flex-end;
  align-items: flex-end;
  width: 100%;
}

.add_employees_btn_container button {
  background-color: #ee1c25;
  border-radius: 10px;
  padding: 10px 9px;
  font-size: 12px;
  box-shadow: 0 15px 20px 0 rgb(238 28 37 / 30%);
  font-weight: 400;
  border: none;
  color: white;
  font-weight: 500;
  width: 100%;
  max-width: 170px;
}

.add_employees_btn_container button svg {
  margin-right: 5px;
  font-size: 17px;
}

.employee_card {
  margin-top: 40px;
  width: 100%;
  background-color: white;
  border-radius: 60px;
  padding-bottom: 20px !important;
  padding: 15px 25px;
  box-shadow:
    rgb(50 50 93 / 10%) 0px 0px 100px -20px,
    rgb(0 0 0 / 20%) 0px 30px 23px -30px !important;

  height: 100%;
  max-height: 420px;
  margin-bottom: 40px;
  position: relative;
}

.avatar_main {
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
}

.avatar_circle {
  width: 67px;
  border: 1px solid #0097d9;
  height: 64px;
  text-align: center;
  border-radius: 100px;
  padding: 1px 3px;
}

.avatar_circle img {
  width: 60px;
  height: 60px;
  object-fit: cover;
  object-position: center;
  border-radius: 100px;
}

.user_Info_container h5 {
  font-weight: 600;
  text-align: center;
  font-size: 20px;
  margin-bottom: 0;
  padding-top: 10px;
  color: #253766;
  text-overflow: ellipsis;
  /* white-space: nowrap; */
  width: 100%;
  overflow: hidden;
}

.user_Info_container p {
  font-size: 13px;
  text-align: center;
  letter-spacing: 1px;
  font-weight: 600;
  color: #858586;
  margin-bottom: 0;
}

.user_Info_container button {
  margin-top: 5px;
  height: 32px !important;
  background-color: #59c680;
  color: white;
  border: none;
  padding: 2px 15px;
  border-radius: 20px;
  font-weight: 500;
}

.user_details_container {
  margin-top: 20px;
  display: flex;
  justify-content: space-between;
}

.user_details_container h5 {
  color: #858586;
  font-weight: 500;
  font-size: 14px;
}

.user_details_container h6 {
  color: #253766;
  font-weight: 500;
  font-size: 16px;
}

.view_profile_btn {
  width: 100%;
}

.view_profile_btn button {
  background-color: #0097d9;
  padding: 10px 20px;
  width: 100%;
  color: white;
  font-weight: 600;
  font-size: 18px;
  border: none;
  border-top-left-radius: 10px;
  border-top-right-radius: 10px;
  border-bottom-left-radius: 35px;
  border-bottom-right-radius: 35px;
}

.edit_employee_dropdown button {
  background-color: transparent;
  border: none;
  padding: 0;
}

.edit_employee_dropdown svg {
  color: #0097d9;
  outline: none;
}

.edit_employee_dropdown .btn-check:checked + .btn,
.btn.active,
.btn.show,
.btn:first-child:active,
:not(.btn-check) + .btn:active {
  background-color: transparent !important;
}

.edit_employee_dropdown button:hover,
.edit_employee_dropdown button:focus {
  background: transparent;
}

.edit_employee_dropdown button::after {
  display: none;
}

.edit_employee_dropdown .btn_edit_Icon,
.edit_employee_dropdown .btn_ban_Icon {
  margin-right: 10px;
}

.btn_ban_Icon {
  color: rgb(230, 50, 50) !important;
}
.btn_un_ban_Icon {
  color: rgb(51, 143, 28) !important;
  font-size: 16px;
}

.update_employee_btn_container button {
  margin-bottom: 20px;
}
.emp_active_btn {
  background-color: rgb(26, 181, 26);
  border: none;
  padding: 0px 40px;
  color: white;
  border-radius: 10px;
  margin-right: 20px;
}
.emp_in_active_btn {
  background-color: red;
  border: none;
  padding: 0px 40px;
  color: white;
  border-radius: 10px;
  margin-right: 20px;
}
.employee_card_bottom{
  position: absolute;
  bottom: 30px;
  width: 90%;
}
@media screen and (max-width: 1350px) {
  .page_responsive label {
    font-size: 12px !important;
  }
}
