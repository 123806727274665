.btn__wrapper{
    display: flex;
    gap: 0px;
    align-items: center;
}
.btn__wrapper button{
    background: transparent;
    box-shadow: none !important;
    margin-right: 24px !important;
    border-radius: 10px !important;
    border: 1px solid #00b1ff;
    color: #0097d9;
}
.btn__wrapper button:hover , .btn__wrapper button:active{
    background: transparent;
    box-shadow: none !important;
    margin-right: 24px !important;
    border-radius: 10px !important;
    border: 1px solid #00b1ff;
    color: #0097d9;
}