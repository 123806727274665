.checkIn_list {
  overflow-y: scroll;
  width: 100%;
  height: 200px;
}
.checkIn_list::-webkit-scrollbar {
  width: 5px;
  cursor: pointer;
}
.checkIn_list::-webkit-scrollbar-track {
  background: #e4e4e5;
}

.checkIn_list::-webkit-scrollbar-thumb {
  background: #14d4e2;
  border-radius: 10px;
  outline: 1px solid #fff;
  cursor: pointer;
}
.tickets_box::-webkit-scrollbar {
  width: 5px;
  cursor: pointer;
}
.tickets_box::-webkit-scrollbar-track {
  background: #e4e4e5;
}

.tickets_box::-webkit-scrollbar-thumb {
  background: #14d4e2;
  border-radius: 10px;
  outline: 1px solid #fff;
  cursor: pointer;
}
.fs-14 {
  font-size: 14px !important;
}
.fs-12 {
  font-size: 12px !important;
}
.fs-11 {
  font-size: 11px !important;
}
.text_left {
  text-align: left !important;
}

table {
  border-collapse: initial !important;
  border-spacing: 0 10px !important;
  padding: 0px !important;
  padding-right: 10px !important;
}

table > tr > th {
  background-color: white !important;
}
/* table > tr {
  background-color: #f3f8fe !important;
} */
table > tr:nth-child(odd) {
  background-color: #8b65ef1a !important;
}
table > tr:nth-child(even) {
  background-color: #5d80e817 !important;
}
table > tr > td:first-child {
  border-top-left-radius: 30px !important;
  border-bottom-left-radius: 30px !important;
}

table > tr > td:last-child {
  border-top-right-radius: 30px !important;
  border-bottom-right-radius: 30px !important;
}
.react-big-calendar > div {
  height: 590px !important;
}

#dashboard_leaves_table th {
  background: white;
  position: sticky;
  top: 0;
  z-index: 99999;
  padding-bottom: 6px;
}
