.auto_complete_employee .wrapper {
  padding: 0 !important;
}

.tickets_management_table {
  margin-top: 50px;
}

.critical_status {
  color: white;
  width: 100%;
  max-width: 80px;
  padding: 4px 5px;
  border-radius: 15px;
  font-weight: 600 !important;
  background-color: rgb(139, 101, 239);
  font-size: 12px !important;
}

.low_status {
  color: white;
  width: 100%;
  max-width: 80px;
  border-radius: 15px;
  padding: 4px 5px;
  font-weight: 600 !important;
  background-color: rgb(89, 198, 128);
  font-size: 12px !important;
}

.high_status {
  width: 100%;
  max-width: 80px;
  color: white;
  border-radius: 15px;
  padding: 4px 5px;
  font-weight: 600 !important;
  background-color: #ff0000;
  font-size: 12px !important;
}

.medium_status {
  width: 100%;
  max-width: 80px;
  color: white;
  padding: 4px 5px;
  border-radius: 15px;
  background-color: #f7941d;
  font-weight: 600 !important;
  font-size: 12px !important;
}
.tickets_management_table .tss-tlx3x1-MUIDataTableToolbar-root {
  display: none !important;
}
.tickets_management_table
  table
  > tbody
  > tr
  > td:first-child
  .tss-1qtl85h-MUIDataTableBodyCell-root {
  justify-content: flex-start !important;
  text-align: start !important;
  /* padding-left: 20px !important; */
}

.tickets_management_table
  table
  > tbody
  > tr
  > td:nth-child(2)
  .tss-1qtl85h-MUIDataTableBodyCell-root {
  justify-content: flex-start !important;
  text-align: start !important;
  /* padding-left: 20px !important; */
}
