.leaves_btn_container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin-top: 10px !important;
}

.leaves_btn_container .add_leave_btn {
  padding: 8px 5px;
  font-size: 13px;
  font-weight: bold;
  width: 100%;
  max-width: 150px;
  color: white;
  background-color: #ee1c25;
  border-radius: 20px;
  margin-bottom: 10px;
  border: none;
}

.leaves_btn_container svg {
  margin-right: 3px;
  font-size: 18px;
  font-weight: bold;
}

.leaves_btn_container .search_btn {
  padding: 8px 5px;
  font-size: 13px;
  font-weight: bold;
  width: 100%;
  max-width: 150px;
  color: white;
  background: linear-gradient(to bottom, #0097d9, #00b1ff);
  border-radius: 20px;
  border: none;
}

.search_btn svg {
  margin-right: 10px;
  font-weight: bold;
}

.leaves_management_table {
  margin-top: 5px;
}
.leaves_management_table .css-13zw5gk-MuiTypography-root:not(:first-child) {
  white-space: nowrap;
  /* width: 100%; */
  /* max-width: 150px; */
  overflow: hidden;
  text-overflow: ellipsis;
}
.leaves_management_table .css-13zw5gk-MuiTypography-root:first-child {
  white-space: nowrap;
  /* width: 100%; */
  /* max-width: 100px; */
  overflow: hidden;
  text-overflow: ellipsis;
}

.leaves_management_btn_container {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 8px;
}

.leaves_management_btn_container button {
  border: none;
  background: transparent;
}

.leaves_management_btn_container .edit_btn {
  background: linear-gradient(180deg, #0097d9, #00b1ff);
  padding: 8px 10px;
  border-radius: 10px;
  text-align: center;
  /* box-shadow: rgba(50, 50, 93, 0.25) 0px 6px 12px -2px, rgba(0, 0, 0, 0.3) 0px 3px 7px -3px; */
}

.leaves_management_btn_container .delete_btn {
  background-color: #fe022c !important;
  padding: 8px 10px;
  border-radius: 10px;
  text-align: center;
  /* box-shadow: rgba(50, 50, 93, 0.25) 0px 6px 12px -2px, rgba(0, 0, 0, 0.3) 0px 3px 7px -3px; */
}

.leaves_management_btn_container .edit_btn svg {
  color: white;
  font-size: 17px;
  position: relative;
  bottom: 2px;
}

.leaves_management_btn_container .delete_btn svg {
  color: white;
  font-size: 17px;
  position: relative;
  bottom: 2px;
}

.leaves_management_table .tss-tlx3x1-MUIDataTableToolbar-root {
  display: none !important;
}

.leaves_management_table
  table
  > tbody
  > tr
  > td:first-child
  .tss-1qtl85h-MUIDataTableBodyCell-root {
  justify-content: flex-start !important;
  text-align: start !important;
}

.created_at_container h6 {
  margin-bottom: 0;
  font-weight: 500;
  color: rgb(100, 99, 99);
}

.pending_status {
  color: #c6f225;
  font-weight: 600 !important;
}
.medium_status {
  color: #f2c225;
  font-weight: 600 !important;
}

.approved_staus {
  color: green;
  font-weight: 600 !important;
}

.rejected_status {
  color: red;
  font-weight: 600 !important;
}

.modal_decision {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 10px;
  padding: 10px 20px;
}

.modal_decision .bg_red {
  padding: 6px 35px;
  background-color: red;
  font-weight: 500;
  color: white;
  box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
  border-radius: 20px;
  border: none;
}

.modal_decision .no_btn {
  padding: 6px 35px;
  font-weight: 500;
  background-color: #00b1ff;
  box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
  color: white;
  border-radius: 20px;
  border: none;
}

.modal_Text {
  text-align: center;
  font-size: 20px;
  font-weight: 500;
}
.MuiPickersPopper-root .css-1mtsuo7 .MuiPopperUnstyled-root {
  z-index: 999999 !important;
}
@media (max-width: 1150px) {
  .add_leave_btn,
  .search_btn {
    font-size: 11px;
  }
}
