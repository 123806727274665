.attendanceTable
  .MuiTableCell-root.MuiTableCell-head.MuiTableCell-sizeMedium.tss-10syd3x-MUIDataTableHeadCell-root.tss-gm6zfk-MUIDataTableHeadCell-fixedHeader.css-1ygcj2i-MuiTableCell-root {
  font-size: 15px !important;
  font-weight: 600 !important;
  width: 50px !important;
  padding: 10px 0px !important;
  background: white !important;
  color: #253766 !important;
}

.align-text {
  /* text-align: center; */
}
time {
  font-size: 28px !important;
  font-weight: 700 !important;
  margin-left: 10px;
  color: #253766;
}

#attendance_table th {
  position: sticky;
  background: white;
  z-index: 99999;
  top: 0;
  padding-bottom: 10px;
}
.MuiPickersPopper-root {
  z-index: 99999 !important;
}
#attendance_table > tr:nth-child(odd) {
  background-color: #8b65ef1a !important;
}
#attendance_table > tr:nth-child(even) {
  background-color: #5d80e817 !important;
}
#attendance_table > tr > td:nth-child(1) {
  padding-left: 5px !important;
}
