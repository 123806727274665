@import url("https://fonts.googleapis.com/css2?family=Montserrat:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800&display=swap");
* {
  margin: 0;
  padding: 0;
  font-family: "Montserrat", sans-serif !important;
}

body {
  overflow-y: hidden;
}

h1,
h2,
h3,
h4,
h5,
h6,
p {
  padding: 0%;
  margin: 0%;
}

.active ~ .page_responsive {
  width: calc(100% - 285px);
  border-top-right-radius: 30px;
  border-top-left-radius: 30px;
  left: 260px;
  transition: all 0.2s ease;
  overflow-x: hidden;
  margin-top: 30px;
}
.page_responsive {
  position: absolute;
  /* padding-left: 10px; */
  margin-right: 15px;
  padding-top: 1%;
  height: 100%;
  background-color: #f3f8fe;
  width: calc(100% - 103px);
  border-top-left-radius: 30px;
  border-top-right-radius: 30px;
  margin-top: 30px;
  left: 75px;
  transition: all 0.2s ease;
  overflow-x: hidden;
  padding-bottom: 60px;
}
.page_responsive::-webkit-scrollbar {
  margin-top: 3px;
  width: 5px;
  cursor: pointer;
}
.page_responsive::-webkit-scrollbar-track {
  background: #e4e4e5;
}

.page_responsive::-webkit-scrollbar-thumb {
  background: #14d4e2;
  border-radius: 10px;
  outline: 1px solid #fff;
  cursor: pointer;
}
table {
  border-collapse: initial !important;
  border-spacing: 0 10px !important;
  padding: 10px 20px;
}

table > tr > th {
  background-color: white !important;
}
table > tr {
  background-color: #f3f8fe !important;
}
table > tr > td:first-child {
  border-top-left-radius: 30px !important;
  border-bottom-left-radius: 30px !important;
}

table > tr > td:last-child {
  border-top-right-radius: 30px !important;
  border-bottom-right-radius: 30px !important;
}
/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none !important;
  margin: 0;
}

/* Firefox */
input[type="number"] {
  -moz-appearance: textfield !important;
}

.MuiModal-root {
  z-index: 99999 !important;
}
.css-segi59.Mui-error .MuiOutlinedInput-notchedOutline {
  border-color: #cccccc !important;
}
.css-154xyx0-MuiInputBase-root-MuiOutlinedInput-root.Mui-error
  .MuiOutlinedInput-notchedOutline {
  border-color: #cccccc !important;
}
.css-1anqmj6-MuiPopper-root-MuiPickersPopper-root {
  z-index: 99999 !important;
}
.hzIGTR {
  height: 40px !important;
}
.form-control {
  max-height: 40px !important;
}
