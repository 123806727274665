.tss-qbo1l6-MUIDataTableToolbar-actions button:not(:nth-child(2)) {
  display: none !important;
}
.tss-qbo1l6-MUIDataTableToolbar-actions svg {
  fill: #3071d3 !important;
}

.time_sheet_table
  table
  > tbody
  > tr
  > td:first-child
  .tss-1qtl85h-MUIDataTableBodyCell-root {
  justify-content: flex-start !important;
  text-align: start !important;
  /* padding-left: 20px !important; */
}
.time_sheet_table
  table
  > tbody
  > tr
  > td:first-child
  .tss-1qtl85h-MUIDataTableBodyCell-root
  div {
  margin-left: 10px !important;
}
.time_sheet_table {
  margin: 5px 0;
}
.time_sheet_table .tss-djbknv-MUIDataTablePagination-navContainer {
  display: none !important;
}

.time_sheet_main .wrapper {
  padding: 3px;
}
.time_sheet_table .css-1bigob2 {
  padding: 10px !important;
}
.monthly_timesheet_table .css-1ygcj2i-MuiTableCell-root {
  line-height: 1.2rem !important;
}
.employee__name__label___{
  color: #394767 !important;
  font-size: 15px !important;
  font-weight: 700 !important;
  margin-bottom: 10px;
}
.createAtt__btn{
  width: 100%;
}
.createAtt__btn button{
  padding: 10px 20px;
    background: linear-gradient(to bottom, #0097d9, #00b1ff);
    box-shadow: 0 15px 20px 0 rgb(13 110 253 / 30%);
    border: none;
    width: 100%;
    font-size: 12px;
    font-weight: 500;
    border-radius: 10px;
    color: white;
}
.search_btn_container{
  margin-bottom: 10px;
}
.create__attendence__btn__color{
  background: #ee1c25 !important;
  padding: 10px 15px !important; 
}


.download__btn__main__timesheet{
  background: linear-gradient(to bottom, #0097d9, #00b1ff) !important;
  border: 1px solid #00b1ff;
  padding: 0px 10px;
  height: 40px;
  color: #fff;
  text-decoration: none;
  display: inline-block;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 5px;
  margin-top: 5px;
    margin-right: 20px;
}
.download__btn__main__timesheet:hover{
  color: #fff;
}
.downLoad__modal__wrapper{
  max-height: 430px;
  overflow-y: auto;
}
.link__color{
  cursor: pointer;
}
.link__color:hover{
  color: blue;
}
@media screen and (max-width: 1400px) {
  .time_sheet_table table > tbody > tr > td {
    font-size: 0.75rem !important;
  }
}
