.plus {
  padding: 0 12px;
  background-color: #23ad23;
  border: none;
  color: #fff;
  border-radius: 4px;
}

.minus {
  padding: 0 12px;
  background-color: rgb(214 66 66);
  border: none;
  color: #fff;
  border-radius: 4px;
}

.inc_dec span {
  margin: 0 8px;
}
.payroll_container {
  background-color: white;
  border-radius: 50px;
  box-shadow:
    rgb(50 50 93 / 10%) 0px 0px 100px -20px,
    rgb(0 0 0 / 20%) 0px 30px 23px -30px !important;
  padding: 15px 20px;
  height: 100%;
}
.payroll_update_btn {
  background: linear-gradient(to bottom, #0097d9, #00b1ff) !important;
  border: none !important;
  margin-right: 20px !important;
  border-radius: 30px !important;
  padding: 10px 20px !important;
}
.generate_slip_btn {
  background: linear-gradient(rgb(13, 200, 31), rgb(20, 125, 18)) !important;
  border: none !important;
  margin-right: 20px !important;
  border-radius: 30px !important;
  padding: 10px 20px !important;
}
.generate_modal_btn {
  background: linear-gradient(to bottom, #0097d9, #00b1ff) !important;
  border: none !important;
  border-radius: 30px !important;
  padding: 10px 20px !important;
}
.payroll_btn_container {
  display: flex;
  justify-content: flex-end;
  align-items: flex-end;
  width: 100%;
}
.payroll__search_btn_container {
  display: flex;
  justify-content: flex-start;
  align-items: flex-end;
  width: 100%;
  height: 100%;
}
.payroll__search_btn_container .MuiIconButton-root {
  background: linear-gradient(to bottom, #0097d9, #00b1ff) !important;
  border-radius: 10px;
  color: white;
  font-size: 30px;
  margin-right: 5px;
}

.payroll_table .tss-tlx3x1-MUIDataTableToolbar-root {
  display: block !important;
}
.month_date_tab button:nth-child(1) {
  border: 1px solid rgb(211, 211, 211);
  border-top-left-radius: 15px;
  border-bottom-left-radius: 15px;
  padding: 5px 15px;
  background-color: transparent;
}
.month_date_tab button:nth-child(2) {
  border: 1px solid rgb(211, 211, 211);
  border-top-right-radius: 15px;
  border-bottom-right-radius: 15px;
  padding: 5px 15px;
  background-color: transparent;
}
.month_date_tab button.active {
  border: 1px solid #00b1ff;
  background: linear-gradient(to bottom, #0097d9, #00b1ff) !important;
  color: white;
}
.downLoad__modal__wrapper{
  padding: 20px;
}
.download__btn__main{
  background: linear-gradient(to bottom, #0097d9, #00b1ff) !important;
  border: 1px solid #00b1ff;
  width: 100%;
  height: 40px;
  color: #fff;
  text-decoration: none;
  display: inline-block;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 5px;
}
.download__btn__main:hover{
  color: #fff;
}
.download__wrapper{
  display: flex;
  justify-content: end;
  padding: 10px 20px;
  background-color: transparent;
}
.download__wrapper button{
  background: linear-gradient(to bottom, #0097d9, #00b1ff) !important;
  border: 1px solid #00b1ff;
  width: fit-content;
  height: 40px;
  color: #fff;
  text-decoration: none;
  display: inline-block;
  border-radius: 5px;
  margin-top: 5px;
  margin-right: 40px;
}


.input____text input::-webkit-outer-spin-button,
.input____text input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}
.input____text input:hover[type=number] {
  -moz-appearance: textfield !important;
}
.input____text input::placeholder {
  font-size: 15px;
  font-weight: 600;
}