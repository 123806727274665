.sidebar {
  position: fixed;
  top: 0;
  left: 0;
  height: 100vh;
  background-color: white;
  width: 74px;
  /* padding-top: 26px; */
  /* box-shadow: -11px 6px 38px -4px black; */

  /* box-shadow: rgba(60, 64, 67, 0.3) 0px 1px 2px 0px,
      rgba(60, 64, 67, 0.15) 0px 1px 3px 1px; */
  z-index: 99;
  /* overflow-x: hidden; */
  transition: all 0.4s ease;
}

.nav_list {
  padding: 10px 10px 10px 10px;
  padding-top: 30px;
  height: 82vh;
  overflow-y: scroll;
  overflow-x: hidden;
  background-color: white !important;
}

.nav_list li a {
  display: flex;
  align-items: center;
  color: #000;
  /* background: linear-gradient(to left, #3077F2, #9468FF); */
  text-decoration: none;
  font-size: 14px;
  padding: 12px 0px 12px 10px;
  transition: all 0.2s ease;
  margin-bottom: 5px;
  font-weight: 400;
}

.nav_list li:hover {
  cursor: pointer;
  /* background-color: #e9ecef; */
  background-color: #f2f5f7;
  color: black !important;
}

.nav_list li {
  transition: all 0.3s ease;
  background-color: #f3f8fe;
  margin: 10px 0;
  border-radius: 10px;
}

.nav_list li a:hover {
  color: black !important;
}

.nav_list li a span {
  margin-left: 12px;
  opacity: 0;
}

.nav_list li a svg {
  transform: translateX(6px);
  min-width: 20px;
  font-size: 18px;
  color: #495057;
}

.nav_list li:hover svg {
  color: grey !important;
}

.profile {
  color: #f3f3f3;
  display: flex;
  align-items: center;
  width: 100%;
  height: 50px;
  padding-top: 52px;
  opacity: 0;
  justify-content: center;
}

.profile p {
  padding: 20px;
  border-radius: 0 0 70px 70px;
}

.profile img {
  width: 220px;
  object-fit: cover;
}

.sidebar.active .logo_content {
  height: 100%;
  /* max-height: 150px; */
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 50px;
}

.logo_content {
  height: 100%;
  max-height: 60px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.logo_content .fa-bars {
  color: #0097d9;
  position: absolute;
  left: 50%;
  font-size: 25px;
  text-align: center;
  top: 30px;
  cursor: pointer;
  transform: translateX(-50%);
  transition: all 0.5s ease;
}

.sidebar.active {
  width: 260px;
  /* border-top-right-radius: 60px; */
  background: white;
  /* border-bottom-right-radius: 60px; */
  /* border-right: 8px solid #3077F2; */
}

.nav_active,
.nav_list li.nav_active:hover {
  /* background-color: #17191f !important; */
  background: linear-gradient(to bottom, #0097d9, #00b1ff);
  color: white !important;
  box-shadow: 0px 8px 15px rgba(8, 100, 141, 30%) !important;

  border-radius: 10px;
}

.sidebar.active .profile {
  opacity: 1;
}

.sidebar.active .nav_list li a span {
  opacity: 1;
  color: #394767;

  font-weight: 500;
}

.sidebar.active .logo_content .fa-bars {
  left: 90%;
}

.nav_active {
  background-color: #17191f;
}

.nav_active a svg,
.nav_active a span {
  color: #f3f3f3 !important;

  font-weight: 600;
}

/* .nav_active a img {
    filter: brightness(0) invert(1);
  } */

.sidebar .nav_list li a img {
  position: relative;
  left: 2px;
}

.sidebar.active .nav_list li a img {
  position: relative;
  left: 0px;
}

.nav_list::-webkit-scrollbar {
  width: 5px;
}

.nav_list::-webkit-scrollbar-thumb {
  background: #fff;
  border-radius: 10px;
  outline: 1px solid #fff;
}

.dashboard_nav {
  margin-left: auto;
}

/* Bread Crumb */

.breadcrumb_main {
  background: #d8dce3;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 50px;
}

.breadcrumb_container {
  width: 64%;
  position: relative;
  float: left;
  display: flex;
}

.breadcrumb_container h4 {
  margin: 0;
  font-size: 16px;
  font-weight: 400;
  color: black;
}
