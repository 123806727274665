.payroll__search_btn button {
  border: none;
  padding: 8px 30px;
  color: white;
  border-radius: 10px;
  background: linear-gradient(to bottom, #0097d9, #00b1ff) !important;
}

.payroll__search_btn button svg {
  font-size: 20px;
}

.monthly_year_container {
  display: flex;
  justify-content: end;
  align-items: end;
  gap: 10px;
}

.monthly_year_container .form_group {
  width: 100%;
  max-width: 250px;
}
