.Button_style {
  background: linear-gradient(to bottom, #0097d9, #00b1ff);
  border: 2px solid #fff;
  font-weight: 500;
  color: #fff;
  padding: 7px 40px !important;
  text-align: center;
  transition: all 0.4s ease-out;
  font-size: 16px;
  padding: 15px 0;
  border-radius: 10px;
}
