.form-label {
  font-size: 13px;
  margin-left: 1px;
  text-transform: uppercase;
  font-weight: 700;
  margin-top: 0px;
  color: #394767 !important;
}

small {
  font-size: 12px !important;
  padding-left: 10px;
}

.css-yk16xz-control,
.css-1pahdxg-control {
  padding: 0 10px !important;
}

.form-control,
.css-yk16xz-control,
.css-1pahdxg-control,
input[type="file"],
.form-select {
  background-color: #f3f8fe !important;
  border-radius: 10px !important;
  padding: 10px 20px !important;
  font-size: 12px !important;
  font-weight: 500;
  text-overflow: ellipsis;
}

.form-control::placeholder {
  color: #717171 !important;
  padding-left: 3px !important;
  font-size: 11px !important;
}
.form-control .form-select::placeholder {
  font-size: 11px !important;
}

.form-control:focus-visible,
.form-select:focus-visible {
  outline: none !important;
  /* border: 1px solid $button !important; */
}

.form-control:focus,
.form-select:focus-visible {
  box-shadow: none !important;
}

.form-control:disabled {
  background-color: #e9ecef !important;
}
