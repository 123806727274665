.autoComplete .wrapper {
  background-color: #f3f8fe !important;
  border-radius: 8px !important;
  box-shadow: none !important;
  border: 1px solid lightgray !important;
  font-size: 14px !important;
  color: lightgray !important;
  z-index: 101 !important;
}
.autoComplete .wrapper > div {
  min-height: 38px !important;
}
.autoComplete .ellipsis {
  color: black !important;
}
.autoComplete .wrapper input::placeholder {
  font-size: 10px !important;
}
.autoComplete .wrapper svg {
  width: 20px !important;
  height: 20px !important;
}
.autoComplete .jqLmKL {
  height: 40px !important;
}
