.edit_profile .avatar img,
.view_emp .avatar img {
  width: 140px;
  height: 140px;
  object-fit: cover;
  object-position: top;
  border-radius: 100px;
}

/* .edit_profile .imgSection {
    display: flex;
    flex-direction: column;
    align-items: center;
    border-right: 1px solid #21556c;
} */

.input_file .file_input {
  width: 0.1px;
  height: 0.1px;
  opacity: 0;
  overflow: hidden;
  position: absolute;
  z-index: -1;
}

.input_file .file_label {
  cursor: pointer;
  display: inline-flex;
  align-items: center;
  border-radius: 4px;
  font-size: 12px;
  font-weight: 600;
  color: #21556c;
  padding: 10px 12px;
}

.input_file .file_label svg {
  height: 16px;
  margin-right: 4px;
}

.change_profile_btn_container {
  position: absolute;
  display: flex;
  gap: 10px;
  margin-left: 15px;
  padding-top: 20px;
}

.change_profile_btn_container button {
  padding: 4px 16px;
  background: linear-gradient(to bottom, #0097d9, #00b1ff);
  border: none;
  box-shadow: 0 15px 20px 0 rgb(13 110 253 / 30%);
  border-radius: 10px;
  color: white;
  font-weight: 600;
  font-size: 12px;
}

.change_profile_btn_container button svg {
  font-size: 20px;
  margin-right: 4px;
}

.change_password_form_container {
  padding: 20px 40px;
}

.form_inner_container {
  display: flex;
  flex-direction: column;
  margin-bottom: 25px;
}

.form_inner_container label {
  font-size: 18px;
  font-weight: 600;
  padding-bottom: 5px;
  color: #253766;
}

.form_inner_container input {
  border-radius: 5px;
  border: 1px solid rgb(199, 197, 197);
  padding: 10px 10px;
  background-color: #f3f8fe;
  width: 100%;
}

.form_inner_container input:focus-visible {
  outline: 2px solid rgb(32, 163, 196);
  border: none;
}

.password_submit_container {
  text-align: end;
}

.password_submit_container button {
  padding: 8px 30px;
  background: linear-gradient(to bottom, #0097d9, #00b1ff);
  border: none;
  box-shadow: 0 15px 20px 0 rgb(13 110 253 / 30%);
  border-radius: 10px;
  color: white;
  font-weight: 600;
  font-size: 14px;
}
