._detail_avatar_circle {
  width: 107px;
  border: 1px solid #0097d9;
  height: 104px;
  text-align: center;
  border-radius: 100px;
  padding: 1px 3px;
}

._detail_avatar_circle img {
  width: 100px;
  height: 100px;
  object-fit: cover;
  object-position: center;
  border-radius: 100px;
}
.documents {
  padding: 20px 0px;
  display: flex;
  align-items: center;
  overflow-x: auto;
}
.documents::-webkit-scrollbar {
  margin-top: 3px;
  width: 3px;
  height: 8px;
  cursor: pointer;
}
.documents::-webkit-scrollbar-track {
  background: #e4e4e5;
}

.documents::-webkit-scrollbar-thumb {
  background: linear-gradient(to bottom, #0097d9, #00b1ff);
  border-radius: 10px;
  outline: 1px solid #fff;
  cursor: pointer;
}

.download_btn_container button {
  padding: 5px 10px;
  width: 40px;
  height: 40px;
  border: none;
  background: linear-gradient(to bottom, #0097d9, #00b1ff);
  border-radius: 100px;
  font-size: 13px;
  color: white;
  position: relative;
  bottom: 15px;
}

.MuiBox-root .css-k008qs {
  position: relative;
}
.cnic_box {
  position: relative;
  justify-content: center;
  margin: 0 !important;
}
.cnic_download_btn_container {
  margin-top: -20px !important;
}
.cnic_download_btn_container button {
  /* margin-top: -40px !important; */
  padding: 5px 10px;
  width: 40px;
  height: 40px;
  border: none;
  background: linear-gradient(to bottom, #0097d9, #00b1ff);
  border-radius: 100px;
  font-size: 13px;
  color: white;
  /* position: absolute; */
  /* bottom: 3px; */
  /* right: 10px; */
  /* align-items: center; */
  justify-content: center;
  /* display: flex; */
}

.manage_leaves_Btn_container button {
  padding: 4px 16px;
  background: linear-gradient(to bottom, #0097d9, #00b1ff);
  border: none;
  box-shadow: 0 15px 20px 0 rgb(13 110 253 / 30%);
  border-radius: 10px;
  color: white;
  font-weight: 600;
  font-size: 12px;
  margin-top: 20px;
}
