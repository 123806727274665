.mui_datatables .css-2ulfj5-MuiTypography-root {
  margin: 0;
  font-family: "Montserrat";
  font-weight: 500;
  font-size: 20px !important;
  line-height: 1.6;
  letter-spacing: 0.0075em;
  color: #394767 !important;
  font-weight: 700 !important;
}
.tss-58la38-MUIDataTable-paper {
  border-radius: 30px !important;
  border: none !important;
  box-shadow:
    rgb(50 50 93 / 10%) 0px 0px 100px -20px,
    rgb(0 0 0 / 20%) 0px 30px 23px -30px !important;
}
.MuiTable-root {
  padding: 0px 20px !important;
}
.css-1ygcj2i-MuiTableCell-root {
  /* background: linear-gradient(to left, #3077F2, #9468FF); */
}

.MuiTablePagination-selectLabel.css-pdct74-MuiTablePagination-selectLabel,
.MuiTablePagination-displayedRows.css-levciy-MuiTablePagination-displayedRows {
  margin-bottom: 3px !important;
}

.MuiTablePagination-selectLabel.css-1chpzqh,
.MuiTablePagination-displayedRows.css-1chpzqh {
  margin: 0 !important;
}

.tss-gm6zfk-MUIDataTableHeadCell-fixedHeader {
  text-align: center !important;
  background: linear-gradient(to bottom, #0097d9, #00b1ff) !important;
  color: white !important;
  font-size: 12px !important;
}

.tss-178gktx-MUIDataTableHeadCell-contentWrapper {
  display: block !important;
  -webkit-align-items: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
}

.tss-1qtl85h-MUIDataTableBodyCell-root {
  display: flex;
  justify-content: center;
}

.css-1ex1afd-MuiTableCell-root {
  text-align: center !important;
  font-weight: 600 !important;
}

/* Table Rows Different Colors */

table > tbody > tr:nth-child(odd) > td,
table > tbody > tr:nth-child(odd) > th {
  background-color: white;
  border-top: thin solid #1765f9 !important;
  border-bottom: thin solid #1765f9 !important;
  border-right: 0px !important;
}

table > tbody > tr:nth-child(odd) > td:last-child {
  border-right: solid 1px #1765f9 !important;
}

table > tbody > tr:nth-child(odd) > td:first-child {
  border-left: solid 1px #1765f9 !important;
}

table > tbody > tr:nth-child(even) > td,
table > tbody > tr:nth-child(even) > th {
  background-color: white;
  border-top: thin solid #1765f9 !important;
  border-bottom: thin solid #1765f9 !important;
  border-right: 0px !important;
}

table > tbody > tr:nth-child(even) > td:first-child {
  border-left: solid 1px #9468ff !important;
}

table > tbody > tr:nth-child(even) > td:last-child {
  border-right: solid 1px #9468ff !important;
}

table > tbody > tr > td:first-child {
  border-top-left-radius: 30px;
  border-bottom-left-radius: 30px;
}

table > tbody > tr > td:last-child {
  border-top-right-radius: 30px;
  border-bottom-right-radius: 30px;
}

.MuiTableCell-root.MuiTableCell-head.MuiTableCell-sizeMedium.tss-10syd3x-MUIDataTableHeadCell-root.tss-gm6zfk-MUIDataTableHeadCell-fixedHeader.css-1ygcj2i-MuiTableCell-root:first-child {
  padding-left: 10px !important;
}

.MuiTableCell-root.MuiTableCell-head.MuiTableCell-sizeMedium.tss-10syd3x-MUIDataTableHeadCell-root.tss-gm6zfk-MUIDataTableHeadCell-fixedHeader.css-1ygcj2i-MuiTableCell-root:last-child {
  padding-right: 10px !important;
}

/* ---------- end Table Rows Different Colors --------- end */

.user_details_card {
  margin-top: 50px;
}

.download_btn {
  color: white;
  padding: 6px 7px;
  font-size: 14px;
  background: linear-gradient(to left, #3077f2, #9468ff);
  position: relative;
  border-radius: 10px;
  border: none;
  display: flex;
  font-weight: 500;
  top: 3px;
}

.download_btn svg {
  color: white;
  margin-right: 10px;
  position: relative;
  top: 2px;
}

.MuiToolbar-root.MuiToolbar-gutters.MuiToolbar-regular.tss-tlx3x1-MUIDataTableToolbar-root.css-i6s8oy {
  min-height: 10px !important;
}

.MuiTableCell-root.MuiTableCell-body.MuiTableCell-sizeMedium.tss-1qtl85h-MUIDataTableBodyCell-root.css-1ex1afd-MuiTableCell-root {
  padding: 8px 10px !important;
}

#disabled_btn {
  background-color: #aaaeb3 !important;
  box-shadow: none !important;
  cursor: not-allowed;
  border-radius: 10px !important;
  max-width: 200px;
}

.MuiTableCell-root.MuiTableCell-head.MuiTableCell-sizeMedium.tss-10syd3x-MUIDataTableHeadCell-root.tss-gm6zfk-MUIDataTableHeadCell-fixedHeader.css-1ygcj2i-MuiTableCell-root {
  font-size: 12px !important;
  font-weight: 600 !important;
  width: 50px !important;
  padding: 10px 0px !important;
  background: linear-gradient(to bottom, #0097d9, #00b1ff) !important;
  color: white !important;
}

.css-hyum1k-MuiToolbar-root {
  min-height: 50px !important;
}

.MuiTableCell-head .MuiSvgIcon-root {
  color: white !important;
  position: relative;
  left: 6px;
}

.css-nfmglb-MuiTableCell-root {
  padding: 0 !important;
}

.quotes-table table {
  margin-bottom: 0px !important;
}

/* Auto Complete Search */

.sc-ftTHYK.fJPrKL input {
  margin: 0 !important;
}

#users_container .sc-gKPRtg.fSFzAM {
  height: 100%;
  max-height: 400px;
  cursor: pointer;
  overflow-y: scroll !important;
}

.hzIGTR > .wrapper {
  border-radius: 10px !important;
}

#users_container .sc-gKPRtg.fSFzAM::-webkit-scrollbar {
  width: 5px;
  border-radius: 40px;
  height: 10px;
}

/* Track */
#users_container .sc-gKPRtg.fSFzAM::-webkit-scrollbar-track {
  background: #f1f1f1;
  border-radius: 40px;
  height: 10px;
}

/* Handle */
#users_container .sc-gKPRtg.fSFzAM::-webkit-scrollbar-thumb {
  background: #888;
  border-radius: 40px;
  height: 10px;
}

/* Handle on hover */
#users_container .sc-gKPRtg.fSFzAM::-webkit-scrollbar-thumb:hover {
  background: #555;
}

.sc-ftTHYK.hGbHUK {
  min-height: 33px;
}

.sc-ftTHYK.hGbHUK input {
  margin-bottom: 0;
}

.css-lp9mh7 {
  padding: 0 !important;
}

.css-q34dxg {
  padding: 10px !important;
  font-weight: 600 !important;
}

/* Tables */

.css-hyum1k-MuiToolbar-root:nth-child(1) svg {
  fill: #3071d3 !important;
}

table {
  border-collapse: separate !important;
  border-spacing: 0 10px !important;
  padding: 5px 10px;
}

.css-1anx036 {
  /* font-style: italic !important; */
  color: #394767 !important;
  font-weight: 800 !important;
}

.MuiTable-root.tss-900muf-MUIDataTable-tableRoot.css-1owb465 {
  border-collapse: separate !important;
  border-spacing: 0 10px !important;
  padding: 10px 20px;
}

.MuiTableCell-head:first-child {
  border-top-left-radius: 32px;
  border-bottom-left-radius: 24px;
}

.MuiTableCell-head:last-child {
  border-top-right-radius: 32px;
  border-bottom-right-radius: 24px;
}

.expirted_date {
  color: red;
  font-weight: 700;
}

svg.sc-jSUZER.ddQWpX.search-icon {
  /* background: #9468ff !important; */
  /* fill: white !important; */
  width: 20px !important;
  height: 20px !important;
  font-size: 10px !important;
  border-radius: 10px !important;
}
