.create_emp_bg_background {
  margin-top: 50px;
  border-radius: 10px;
  padding: 10px 0px;
}

.details_head {
  padding: 4px 0px;
  background: linear-gradient(to bottom, #0097d9, #00b1ff);
  border-top-left-radius: 32px;
  border-top-right-radius: 32px;
  border-bottom-left-radius: 15px;
  border-bottom-right-radius: 15px;
}

.details_head h5 {
  padding-top: 10px;
  color: white;
  padding-left: 30px;
  padding-bottom: 10px;
  margin-bottom: 0;
  font-weight: 700;
}

.create_emp_bg_background .MuiTypography-h5 {
  font-weight: 600 !important;
  letter-spacing: 2px;
  padding-left: 11px;
}

input[type="radio"] {
  cursor: pointer;
  border: 2px solid #0097d9;
}

.css-13cymwt-control {
  padding: 1px 5px;
  background-color: #f3f8fe !important;
  border-radius: 10px !important;
}

.create_emp_bg_background #inline-radio-1 {
  padding: 5px 5px !important;
}

.create_emp_bg_background label {
  color: #253766 !important;
  margin-bottom: 3px;
  font-weight: 500;
  font-size: 14px;
  text-transform: capitalize;
}

.create_emp_bg_background input[type="file"]::file-selector-button {
  color: #0097d9;
  font-weight: 600;
  background-color: #f1f3fb !important;
  border-radius: 10px !important;
}

.bank_main,
.documents_main {
  margin-top: 40px !important;
}

::-webkit-calendar-picker-indicator {
  background-image: url("../../../../../Asssets/Images/calendar.png");
  width: 20px;
  height: 20px;
  cursor: pointer;
  object-fit: cover;
}

.details_container {
  padding: 10px 20px;
}

.personal_details_container {
  background-color: white;
  border-radius: 50px;
  box-shadow:
    rgb(50 50 93 / 10%) 0px 0px 100px -20px,
    rgb(0 0 0 / 20%) 0px 30px 23px -30px !important;
  margin-bottom: 20px;
  padding: 15px 20px;
}

/* .personal_details_container input, .form-select{
    margin-bottom: 10px;
} */

.form-select {
  cursor: pointer;
}

.create_emp_loader {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 50vh;
}

.css-1s2u09g-control {
  background-color: hsl(0, 0%, 96%) !important;
  border-color: hsl(0, 0%, 96%) !important;
  border-radius: 20px !important;
}

.nic__input_mask input{
  background-color: #f3f8fe !important;
    border-radius: 10px !important;
    padding: 10px 20px !important;
    font-size: 12px !important;
    text-overflow: ellipsis;
    border: 1px solid #ced4da;
    width: 100%;
    outline: none;
}