.main__pin {
  padding: 25px;
  position: relative;
}
.main__pin > span {
  border: 1px solid #00b1ff;
  width: 20px;
  height: 20px;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  position: absolute;
  top: 10px;
  right: 10px;
  cursor: pointer;
  font-size: 20px;
  font-weight: 500;
}
.opt__box label {
  color: #000;
  font-weight: 500;
  margin-bottom: 20px;
  display: flex;
  justify-content: center;
}
.opt__box input {
  width: 50px !important;
  height: 50px;
  font-size: 60px !important;
  caret-color: #fff;
  padding-bottom: 10px;
}
.optCont {
  display: flex;
  justify-content: center;
  gap: 20px;
}
.submitBtnMain {
  margin-top: 20px;
  display: flex;
  justify-content: center;
  align-items: center;
}
