.hrm_list {
  padding: 10px 10px 10px 10px;
  padding-top: 30px;
  /* height: 90vh; */
  /* overflow-y: a; */
  /* overflow-x: hidden; */
  background-color: white !important;
}

.hrm_list li {
  transition: all 0.3s ease;
  background-color: #f3f8fe;
  margin: 10px 0;
  border-radius: 10px;
}
.tabs_btn {
  margin: 0 0 12px 0 !important;
  background-color: #f3f8fe !important;
  color: #394767 !important;
  border: none !important;
  box-shadow: none !important;
  border-radius: 10px !important;
  font-weight: 500 !important;
  font-size: 14px !important;
  padding: 10px 0 !important;
  text-transform: none !important;
}
.edit-btn {
  border: none;
  background: white;
  font-size: 17px;
  color: #0097d9;
  padding: 0 5px;
}
.delete-btn {
  border: none;
  background: white;
  font-size: 17px;
  color: red;
  padding: 0 5px;
}
.departmentsTable .tss-qbo1l6-MUIDataTableToolbar-actions {
  display: none !important;
}
.departmentsTable .css-nfmglb-MuiTableCell-root {
  border: none !important;
}
.departmentsTable .tss-tlx3x1-MUIDataTableToolbar-root {
  display: none !important;
}
.addDepartment_btn {
  background: #ee1c25 !important;
  color: white !important;
  margin-bottom: 20px !important;
  margin-right: 10px !important;
  padding: 8px 20px !important;
  border-radius: 25px !important;
  box-shadow: 0px 8px 15px rgb(8 100 141 / 15%) !important;
}
