.noti_container {
  position: absolute;
  background-color: #f3f8fe;
  top: 80px;
  right: 14px;
  transition: 0.5s;
  overflow-y: scroll;
  box-shadow:
    rgb(50 50 93 / 25%) 0px 13px 27px -5px,
    rgb(0 0 0 / 30%) 0px 8px 16px -8px;
  width: 100%;
  height: 250px;
  max-width: 450px;
  z-index: 99999;
}

.noti_container::-webkit-scrollbar {
  width: 5px;
}

.noti_container::-webkit-scrollbar-thumb {
  background: #00b1ff;
  border-radius: 10px;
  outline: 1px solid #fff;
}

.noti_container h6 {
  margin-bottom: 0;
  border-bottom: 1px solid grey;
  padding: 10px 10px;
}

.avatar_img_container {
  width: 70px;
  height: 70px;
  border-radius: 10px;
  padding: 10px 0px;
}

.avatar_img_container img {
  width: 100%;
  height: 75px;
  border-radius: 10px;
  object-fit: cover;
}

.notification_content h5 {
  font-size: 15px;
  font-weight: 500;
  margin-bottom: 0;
  padding-top: 10px;
}

.notification_content span {
  font-size: 12px;
  font-weight: 600;
}

.notification_content span svg {
  font-size: 16px;
}

.notification_content p {
  font-size: 12px;
  margin-bottom: 0;
  white-space: nowrap;
  width: 300px;
  overflow: hidden;
  text-overflow: ellipsis;
}

.notifications_row {
  padding-bottom: 30px;
}
