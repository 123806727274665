.login_btn {
  background: linear-gradient(to bottom, #0097d9, #00b1ff) !important;
  margin-top: 10px !important;
  box-shadow: 0px 8px 20px rgba(8, 100, 141, 45%) !important;
  width: 100% !important;
  padding: 8px 20px !important;
  color: white !important;
  border: none !important;
}
.password__field__wrapper{
  position: relative;
}
.password__field__wrapper > span{
  position: absolute;
  top: 10px;
  right: 10px;
  cursor: pointer;
}