.site_modal .modal-content {
  border-radius: 15px;
  border-top: 10px solid #1a9abb;
}

.modal-body {
  padding: 30px 0 !important;
  border-radius: 20px !important;
}

.registration_modal {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.review_modal {
  padding: 25px;
}

.review_modal hr {
  width: 95%;
  margin-bottom: 0 !important;
  margin-top: 10px !important;
  margin: auto;
}

.site_modal_title {
  color: #00abe0;
  font-size: 22px;
  letter-spacing: 2px;
}

h5 {
  margin-bottom: 20px;
}

.modal-dialog {
  height: 80vh;
  display: flex;
  justify-content: center;
  align-items: center;
}

.review_header {
  display: contents;
}

.review_header h4 {
  margin-bottom: 0;
}

.review_header svg {
  font-size: 20px;
  color: #1a9abb;
  cursor: pointer;
}

.list_name {
  display: flex;
  justify-content: space-between;
  padding-right: 15px;
}

.list_name h6 {
  margin-bottom: 0;
}
